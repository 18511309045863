import styles from 'sections/team/team.module.css';
import {
    getDesktopSrc2x,
    getDesktopSrc3x,
    getDesktopSrcWebp,
    getDesktopSrcWebp2x,
    getDesktopSrcWebp3x,
    getMobileSrc2x,
    getMobileSrc3x,
    getMobileSrcWebp,
    getMobileSrcWebp2x,
    getMobileSrcWebp3x,
} from 'utils/imageResize.utils';

interface IOptimizedBackground {
    mobileSrc: string;
    desktopSrc: string;
    children: JSX.Element;
}

const OptimizedBackground = ({
    mobileSrc,
    desktopSrc,
    children, // For overlay content
}: IOptimizedBackground) => {
    const desktopSrc2x = getDesktopSrc2x(desktopSrc);
    const desktopSrc3x = getDesktopSrc3x(desktopSrc);

    const desktopSrcWebp = getDesktopSrcWebp(desktopSrc);
    const desktopSrcWebp2x = getDesktopSrcWebp2x(desktopSrcWebp);
    const desktopSrcWebp3x = getDesktopSrcWebp3x(desktopSrcWebp);

    const mobileSrc2x = getMobileSrc2x(mobileSrc);
    const mobileSrc3x = getMobileSrc3x(mobileSrc);

    const mobileSrcWebp = getMobileSrcWebp(mobileSrc);
    const mobileSrcWebp2x = getMobileSrcWebp2x(mobileSrcWebp);
    const mobileSrcWebp3x = getMobileSrcWebp3x(mobileSrcWebp);

    return (
        <div className={styles.backgroundContainer}>
            <picture>
                <source
                    type="image/webp"
                    media="(max-width: 600px)"
                    srcSet={`${mobileSrcWebp} 1x, ${mobileSrcWebp2x} 2x, ${mobileSrcWebp3x} 3x`}
                    sizes="(max-width: 600px) 100vw"
                />

                <source
                    type="image/png"
                    media="(max-width: 600px)"
                    srcSet={`${mobileSrc} 1x,  
                            ${mobileSrc2x} 2x, 
                            ${mobileSrc3x} 3x`}
                    sizes="(max-width: 600px) 100vw"
                />

                <source
                    type="image/webp"
                    srcSet={`${desktopSrcWebp} 1x, ${desktopSrcWebp2x} 2x, ${desktopSrcWebp3x} 3x`}
                    sizes="100vw"
                />

                <img
                    src={desktopSrc}
                    srcSet={`${desktopSrc2x} 2x, ${desktopSrc3x} 3x`}
                    sizes="100vw"
                    alt="background"
                    className={styles.backgroundImage}
                />
            </picture>

            <div className={styles.overlayContent}>{children}</div>
        </div>
    );
};

export default OptimizedBackground;
