import { useTranslation } from 'react-i18next';
import { IWork } from 'sections/works/interfaces';

export const useGetWorksList = (): IWork[] => {
    const { t } = useTranslation();

    return [
        {
            id: 1,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/sinergetic/sinergetic-424x275%401x.png',
            title: t('works.list.1.title'),
            subtitle: t('works.list.1.subtitle'),
            popupText: t('works.list.1.popupText'),
            popupInfo: t('works.list.1.popupInfo'),
            websiteUrl: 'https://sni.ae/',
        },
        {
            id: 2,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/englishCourse/engCourse-424x275%401x.png',
            title: t('works.list.7.title'),
            subtitle: t('works.list.7.subtitle'),
            popupText: t('works.list.7.popupText'),
            popupInfo: t('works.list.7.popupInfo'),
            websiteUrl: 'https://yourwonderteacher.com.ua/',
        },
        {
            id: 3,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/cocktailStore/cocktailStore-424x275%401x.png',
            title: t('works.list.5.title'),
            subtitle: t('works.list.5.subtitle'),
            popupText: t('works.list.5.popupText'),
            popupInfo: t('works.list.5.popupInfo'),
            websiteUrl: 'https://mixopro.store/',
        },
        {
            id: 4,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/lightInYou/lightInYou-424x275%401x.png',
            title: t('works.list.18.title'),
            subtitle: t('works.list.18.subtitle'),
            popupText: t('works.list.18.popupText'),
            popupInfo: t('works.list.18.popupInfo'),
            websiteUrl: 'https://www.lightinyou.org.ua/',
        },
        {
            id: 5,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/FT/FT-424x275%401x.png',
            title: t('works.list.15.title'),
            subtitle: t('works.list.15.subtitle'),
            popupText: t('works.list.15.popupText'),
            popupInfo: t('works.list.15.popupInfo'),
            websiteUrl: 'https://www.fairwind-trading.com/',
        },
        {
            id: 6,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/mobimarket/mobimarket-424x275%401x.png',
            title: t('works.list.17.title'),
            subtitle: t('works.list.17.subtitle'),
            popupText: t('works.list.17.popupText'),
            popupInfo: t('works.list.17.popupInfo'),
            videoUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/mobimarket/mobimarketRedesign.mp4',
            websiteUrl: 'https://mobimarkett.com.ua/',
        },
    ];
};

export const useGetOtherWorksList = (): IWork[] => {
    const { t } = useTranslation();

    return [
        {
            id: 1,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/monolitDoors/monolitDoors-424x275%401x.png',
            title: t('works.list.16.title'),
            subtitle: t('works.list.16.subtitle'),
            popupText: t('works.list.16.popupText'),
            popupInfo: t('works.list.16.popupInfo'),
            videoUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/monolitDoors/MonolithRedesign.mp4',
        },
        {
            id: 2,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/davydenkoCosmetics/davydenkoCosmetics-424x275%401x.png',
            title: t('works.list.3.title'),
            subtitle: t('works.list.3.subtitle'),
            popupText: t('works.list.3.popupText'),
            popupInfo: t('works.list.3.popupInfo'),
            websiteUrl: 'https://www.davydenkobeauty.com/en/',
        },
        {
            id: 3,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/hvostyky/hvostyky-424x275%401x.png',
            title: t('works.list.19.title'),
            subtitle: t('works.list.19.subtitle'),
            popupText: t('works.list.19.popupText'),
            popupInfo: t('works.list.19.popupInfo'),
        },
        {
            id: 4,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/hildis/hildis-424x275%401x.png',
            title: t('works.list.20.title'),
            subtitle: t('works.list.20.subtitle'),
            popupText: t('works.list.20.popupText'),
            popupInfo: t('works.list.20.popupInfo'),
        },
        {
            id: 5,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/crmSystem/crmSystem-424x275%401x.png',
            title: t('works.list.11.title'),
            subtitle: t('works.list.11.subtitle'),
            popupText: t('works.list.11.popupText'),
            popupInfo: t('works.list.11.popupInfo'),
            websiteUrl: 'https://www.behance.net/gallery/163317257/CRM-system',
        },
        {
            id: 6,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/cosmetologyCenter/cosmetologyCenter-424x275%401x.png',
            title: t('works.list.6.title'),
            subtitle: t('works.list.6.subtitle'),
            popupText: t('works.list.6.popupText'),
            popupInfo: t('works.list.6.popupInfo'),
            websiteUrl: 'https://dr-soboleva.com/',
        },
        {
            id: 7,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/smmCourse/smmCourse-424x275%401x.png',
            title: t('works.list.8.title'),
            subtitle: t('works.list.8.subtitle'),
            popupText: t('works.list.8.popupText'),
            popupInfo: t('works.list.8.popupInfo'),
        },
        {
            id: 9,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/bookReader/bookReader-424x275%401x.png',
            title: t('works.list.10.title'),
            subtitle: t('works.list.10.subtitle'),
            popupText: t('works.list.10.popupText'),
            popupInfo: t('works.list.10.popupInfo'),
            websiteUrl:
                'https://www.behance.net/gallery/159112149/Mobile-app-for-reading',
        },
        {
            id: 10,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/elzaStudio/elzaStudio-424x275%401x.png',
            title: t('works.list.4.title'),
            subtitle: t('works.list.4.subtitle'),
            popupText: t('works.list.4.popupText'),
            popupInfo: t('works.list.4.popupInfo'),
            websiteUrl: 'https://www.elza-studio.com/',
        },
        {
            id: 11,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/barexKitchens/barexKitchens-424x275%401x.png',
            title: t('works.list.2.title'),
            subtitle: t('works.list.2.subtitle'),
            popupText: t('works.list.2.popupText'),
            popupInfo: t('works.list.2.popupInfo'),
            websiteUrl: 'https://barex.in.ua/follower',
        },
        {
            id: 12,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/coworkingSpace/coworkingSpace-424x275%401x.png',
            title: t('works.list.12.title'),
            subtitle: t('works.list.12.subtitle'),
            popupText: t('works.list.12.popupText'),
            popupInfo: t('works.list.12.popupInfo'),
            websiteUrl:
                'https://www.behance.net/gallery/160398465/Website-design-for-a-co-working-space',
        },
        {
            id: 13,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/flowerShop/flowerShop-424x275%401x.png',
            title: t('works.list.13.title'),
            subtitle: t('works.list.13.subtitle'),
            popupText: t('works.list.13.popupText'),
            popupInfo: t('works.list.13.popupInfo'),
            websiteUrl:
                'https://www.behance.net/gallery/153946911/Flower-shop-website-redesign',
        },
        {
            id: 14,
            imageUrl:
                'https://velvety-works.s3.eu-north-1.amazonaws.com/constructionCompany/constructionCompany-424x275%401x.png',
            title: t('works.list.14.title'),
            subtitle: t('works.list.14.subtitle'),
            popupText: t('works.list.14.popupText'),
            popupInfo: t('works.list.14.popupInfo'),
            websiteUrl:
                'https://www.behance.net/gallery/154418129/UI-of-a-construction-company',
        },
    ];
};
