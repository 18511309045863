interface ITitlePositionBox {
    bottom: string;
    left: string;
    rotation: string;
    children: JSX.Element;
}

export const TitlePositionBox = ({
    bottom,
    left,
    rotation,
    children,
}: ITitlePositionBox) => {
    return (
        <div
            style={{
                bottom: bottom,
                left: left,
                rotate: rotation,
                position: 'absolute',
            }}
        >
            {children}
        </div>
    );
};
