import styles from 'sections/team/team.module.css';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import OptimizedBackground from 'sections/team/OptimizedBackground';
import { OptimizedTeamImg } from 'sections/team/OptimizedTeamImg';
import { TitleBox } from '../../components/titleBox/titleBox';
import { TitlePositionBox } from './titlePositionBox';
import { AnimatedPart } from '../../components/animatedPart/AnimatedPart';
import { IS_MOBILE } from '../../constants/responsive';

export const Team = () => {
    const { t } = useTranslation();
    return (
        <section id="section-team" className={styles.sectionTeam}>
            <Element name="team" className={styles.team}>
                <OptimizedBackground
                    mobileSrc="https://velvety-general.s3.eu-north-1.amazonaws.com/team/team-bg-m%401x.png"
                    desktopSrc="https://velvety-general.s3.eu-north-1.amazonaws.com/team/team-bg-d%401x.png"
                >
                    <div className={styles.overlayContent}>
                        <OptimizedTeamImg
                            desktopSrc="https://velvety-general.s3.eu-north-1.amazonaws.com/team/team-d%401x.png"
                            mobileSrc="https://velvety-general.s3.eu-north-1.amazonaws.com/team/team-m%401x.png"
                        />
                        <div className={styles.titleBox}>
                            <h3 className={styles.title}>{t('team.title')}</h3>
                        </div>

                        <TitlePositionBox
                            bottom={IS_MOBILE ? '45%' : '454px'}
                            left={IS_MOBILE ? '1%' : '3%'}
                            rotation="-10deg"
                        >
                            <AnimatedPart isTeam={true}>
                                <TitleBox text="Operation manager" />
                            </AnimatedPart>
                        </TitlePositionBox>

                        <TitlePositionBox
                            bottom={IS_MOBILE ? '67%' : '514px'}
                            left={IS_MOBILE ? '40%' : '20%'}
                            rotation={IS_MOBILE ? '-5deg' : '0deg'}
                        >
                            <AnimatedPart isTeam={true}>
                                <TitleBox text="Frontend developer" />
                            </AnimatedPart>
                        </TitlePositionBox>

                        <TitlePositionBox
                            bottom={IS_MOBILE ? '35%' : '444px'}
                            left={IS_MOBILE ? '45%' : '37%'}
                            rotation={IS_MOBILE ? '10deg' : '7deg'}
                        >
                            <AnimatedPart isTeam={true}>
                                <TitleBox text="Project Manager" />
                            </AnimatedPart>
                        </TitlePositionBox>

                        <TitlePositionBox
                            bottom={IS_MOBILE ? '57%' : '454px'}
                            left={IS_MOBILE ? '25%' : '55%'}
                            rotation={IS_MOBILE ? '0deg' : '-9deg'}
                        >
                            <AnimatedPart isTeam={true}>
                                <TitleBox text="Graphic designer" />
                            </AnimatedPart>
                        </TitlePositionBox>

                        <TitlePositionBox
                            bottom={IS_MOBILE ? '75%' : '414px'}
                            left={IS_MOBILE ? '1%' : '72%'}
                            rotation={IS_MOBILE ? '-18deg' : '0deg'}
                        >
                            <AnimatedPart isTeam={true}>
                                <TitleBox text="UI/UX designer" />
                            </AnimatedPart>
                        </TitlePositionBox>

                        <TitlePositionBox
                            bottom={IS_MOBILE ? '82%' : '444px'}
                            left={IS_MOBILE ? '43%' : '85%'}
                            rotation="12deg"
                        >
                            <AnimatedPart isTeam={true}>
                                <TitleBox text="Backend developer" />
                            </AnimatedPart>
                        </TitlePositionBox>
                    </div>
                </OptimizedBackground>
            </Element>
        </section>
    );
};
