import React from 'react';
import styles from 'components/animatedPart/animatedPart.module.css';
import { useIntersectionObserver } from 'hooks/useInterspectionObserver';

interface AnimatedPartProps {
    children: React.ReactNode;
    delay?: string;
    className?: string;
    isTeam?: boolean;
}

export const AnimatedPart: React.FC<AnimatedPartProps> = ({
    children,
    delay,
    className,
    isTeam,
}) => {
    const { ref, isVisible } = useIntersectionObserver();

    const transitionDelay = delay || 'none';
    const classes = isTeam
        ? `${styles.hiddenTeam} ${isVisible ? styles.visibleTeam : ''} ${className}`
        : `${styles.hidden} ${isVisible ? styles.visible : ''} ${className}`;

    return (
        <div
            ref={ref}
            className={classes}
            style={{
                transitionDelay,
            }}
        >
            {children}
        </div>
    );
};
