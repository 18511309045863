import React from 'react';
import styles from 'index.module.css';

import 'i18n';

import { Hero } from 'sections/hero/Hero';
import { Header } from 'components/header/Header';
import { useScrollPosition } from 'hooks/localStorage.hooks';
import { HireUsButtonLazy } from 'components/hireUsButton/HireUsButtonLazy';
import { Footer } from 'components/footer/Footer';
import { AboutLazy } from 'sections/about/AboutLazy';
import { Offers } from 'sections/offers/Offers';
import Works from 'sections/works/Works';
import { Contact } from 'sections/contact/Contact';
import { initializeGTM } from 'gtm';
import { Team } from 'sections/team/Team';

initializeGTM();

function App(): React.JSX.Element {
    useScrollPosition();

    return (
        <main className={styles.app}>
            <Header />
            <Hero />
            <div className={styles.container}>
                <AboutLazy />
                <Offers />
                <Works />
            </div>
            <Team />
            <div className={styles.container}>
                <Contact />
            </div>
            <Footer />
            <HireUsButtonLazy />
        </main>
    );
}

export default App;
